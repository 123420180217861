import React from 'react';
import { FaSearch, FaBell, FaBars } from 'react-icons/fa';

const UserNavbar = ({ toggleSidebar }) => {
  return (
    <div className="flex items-center justify-between bg-gray-900 text-white shadow p-4 fixed w-full top-0 z-10 rounded-lg">
      <div className="flex items-center space-x-4">
        <button onClick={toggleSidebar} className="lg:hidden text-white text-2xl">
          <FaBars />
        </button>
        <FaSearch className="text-gray-400" />
        <input type="text" placeholder="Cerca" className="bg-gray-800 rounded-full px-4 py-2 text-gray-400 focus:outline-none" />
      </div>
      <FaBell className="text-gray-400" />
    </div>
  );
};

export default UserNavbar;
