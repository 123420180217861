import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { useSpring, animated } from '@react-spring/web';

function Home() {
  const [showAlert, setShowAlert] = useState(true);
  const [estimatedReadingTime, setEstimatedReadingTime] = useState(0);

  useEffect(() => {
    setEstimatedReadingTime(2);
  }, []);

  const explosion = useSpring({
    from: { scale: 0.5, opacity: 0 },
    to: { scale: 1, opacity: 1 },
    config: { tension: 200, friction: 10 },
  });

  return (
    <div className="min-h-screen flex flex-col bg-gray-900">
      <Navbar />
      <main className="flex-1 container mx-auto mt-5 p-4">
        {showAlert && (
          <div className="relative bg-blue-500 text-white p-4 rounded-lg shadow-lg mb-6">
            <button onClick={() => setShowAlert(false)} className="absolute top-2 right-2 text-white">
              &#x2715;
            </button>
            <div className="flex items-center">
              <img src="https://i.imgur.com/TqU97qu.png" alt="ChatBot" className="h-12 w-12 mr-4" />
              <p>
                Hai delle domande? <Link to="/chatbot" className="underline font-bold">Chatta con il nostro ChatBot</Link> per assistenza immediata!
              </p>
            </div>
          </div>
        )}
        <animated.div style={explosion} className="relative bg-gray-800 p-8 rounded-lg shadow-lg overflow-hidden">
          <div className="absolute inset-0 bg-gray-800 opacity-25"></div>
          <div>
            <h1 className="text-4xl font-bold text-center text-red-500 mb-6">
              Concorsinfo.com: <br /> Semplificare la Ricerca di Documenti per i Concorrenti delle Forze Armate e dell'Ordine
            </h1>
            <p className="text-lg text-white mb-4">
              Nel panorama sempre più competitivo dei concorsi pubblici per le forze armate e dell'ordine, la ricerca di documenti e risorse informative è un elemento essenziale per i candidati che cercano di raggiungere il loro obiettivo di carriera. In questo contesto, Concorsinfo.shop emerge come un progetto innovativo e fondamentale che si propone di semplificare e rendere più efficiente questa ricerca, fornendo agli aspiranti un accesso rapido e affidabile alle informazioni necessarie per prepararsi adeguatamente.
            </p>
            <p className="text-sm text-gray-400 mb-6">Tempo stimato di lettura: {estimatedReadingTime} minuti</p>
          </div>
          <div className="mt-8">
            <h2 className="text-3xl font-bold mb-4 text-red-500">Raccolta Completa di Documenti</h2>
            <p className="text-lg text-white mb-4">
              Il cuore di Concorsinfo.shop è una vasta biblioteca digitale che raccoglie documenti, manuali e risorse legate ai concorsi per le forze armate e dell'ordine. Questa raccolta comprende materiale aggiornato e informativo su una varietà di argomenti, dalle leggi e regolamenti alle procedure operative e molto altro.
            </p>
            <h2 className="text-3xl font-bold mb-4 text-red-500">Facilità d'Uso</h2>
            <p className="text-lg text-white mb-4">
              Il sito è progettato con l'utente in mente. L'interfaccia intuitiva consente una ricerca rapida e precisa dei documenti desiderati, risparmiando tempo prezioso ai concorrenti.
            </p>
            <h2 className="text-3xl font-bold mb-4 text-red-500">Community di Supporto</h2>
            <p className="text-lg text-white mb-4">
              Concorsinfo.com non è solo un repository di documenti; è anche una community di 4mila membri in cui gli utenti possono condividere esperienze, consigli e risorse con altri concorrenti. Questa dimensione sociale del progetto crea un ambiente di supporto reciproco e di crescita.
            </p>
          </div>
          <Link to="/chatbot" className="block text-center text-lg text-blue-500 underline mt-8">
            
          </Link>
        </animated.div>
      </main>
      <Footer />
    </div>
  );
}

export default Home;