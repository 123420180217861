import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { useSpring, animated } from '@react-spring/web';

const groups = [
  {
    id: 1,
    name: 'Gruppo Allievi Agenti Polizia Penitenziaria ⚖️',
    link: 'https://chat.whatsapp.com/KMq43jthiOy7Dclhr5c4mU',
  },
  {
    id: 2,
    name: '🚔 Gruppo Allievi Agenti PDS 2024🚔',
    link: 'https://chat.whatsapp.com/EWALZsHYpfP7bNbIHkLcMA',
  },
  {
    id: 3,
    name: '🔵👮 Gruppo Allievi Carabinieri 👮🔴',
    link: 'https://chat.whatsapp.com/IURvjtBNVCr09IYtY4lLp0',
  },
  {
    id: 4,
    name: '🔰 Gruppo Allievi Finanzieri🔰',
    link: 'https://chat.whatsapp.com/Ii1ogOznjiY4z5abzTmBGi',
  },
  {
    id: 5,
    name: '🪖 Gruppo VFI Esercito Italiano🪖',
    link: 'https://chat.whatsapp.com/KAKLxhPguDsK6ZBvTuyYMg',
  },
  {
    id: 6,
    name: '✈️ Gruppo VFI Aeronautica Militare ✈️',
    link: 'https://chat.whatsapp.com/KuREUeNrkLMErH9HX1LuWk',
  },
  {
    id: 7,
    name: '⚓ Gruppo VFI Marina Militare ⚓',
    link: 'https://chat.whatsapp.com/FKRbGdnXNRnH7DTc8RMfZs',
  },
  {
    id: 8,
    name: '✈️Gruppo Marescialli Aeronautica Militare ✈️',
    link: 'https://chat.whatsapp.com/BUxByHZapJeLa675Mga4MM',
  },
  {
    id: 9,
    name: '🪖Gruppo Marescialli Esercito Italiano 🪖',
    link: 'https://chat.whatsapp.com/GWU0hZqQRHNJsGBYa2Wh6f',
  },
];

function WhatsAppGroups() {
  const fadeIn = useSpring({
    from: { opacity: 0, transform: 'translate3d(0, -20px, 0)' },
    to: { opacity: 1, transform: 'translate3d(0, 0, 0)' },
    config: { duration: 1000 },
  });

  return (
    <div className="min-h-screen flex flex-col bg-gray-900">
      <Navbar />
      <main className="flex-1 container mx-auto mt-5 p-4">
        <animated.div style={fadeIn} className="relative bg-gray-800 p-8 rounded-lg shadow-lg overflow-hidden">
          <h1 className="text-4xl font-bold text-center text-white mb-6">Gruppi WhatsApp</h1>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {groups.map((group) => (
              <div key={group.id} className="relative bg-gray-700 p-6 rounded-lg shadow-lg">
                <h2 className="text-2xl font-bold mb-4 text-white">{group.name}</h2>
                <a
                  href={group.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="block text-center text-lg text-blue-500 underline mt-4"
                >
                  Unisciti al gruppo
                </a>
              </div>
            ))}
          </div>
        </animated.div>
      </main>
      <Footer />
    </div>
  );
}

export default WhatsAppGroups;