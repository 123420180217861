import React from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { useSpring, animated } from '@react-spring/web';

function Documents() {
  const fadeIn = useSpring({
    from: { opacity: 0, transform: 'translate3d(0, -20px, 0)' },
    to: { opacity: 1, transform: 'translate3d(0, 0, 0)' },
    config: { duration: 1000 },
  });

  const documents = [
    {
      id: 1,
      title: 'Documentazione Generale',
      description: 'Scarica la documentazione generale per il concorso.',
      pdfUrl: 'https://example.com/documentazione-generale.pdf',
    },
    {
      id: 2,
      title: 'Lista RAV',
      description: 'Scarica la lista RAV aggiornata.',
      pdfUrl: 'https://example.com/lista-rav.pdf',
    },
    {
      id: 3,
      title: 'Regolamenti e Procedure',
      description: 'Scarica i regolamenti e le procedure operative.',
      pdfUrl: 'https://example.com/regolamenti-procedure.pdf',
    },
  ];

  return (
    <div className="min-h-screen flex flex-col bg-gray-900">
      <Navbar />
      <main className="flex-1 container mx-auto mt-5 p-4">
        <animated.div style={fadeIn} className="relative bg-gray-800 p-8 rounded-lg shadow-lg overflow-hidden">
          <h1 className="text-4xl font-bold text-center text-white mb-6">Documenti</h1>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {documents.map((doc) => (
              <div key={doc.id} className="bg-gray-700 p-6 rounded-lg shadow-lg">
                <h2 className="text-2xl font-bold mb-4 text-white">{doc.title}</h2>
                <p className="text-lg text-white mb-4">{doc.description}</p>
                <a href={doc.pdfUrl} target="_blank" rel="noopener noreferrer" className="block text-center text-lg text-blue-500 underline mt-4">
                  Scarica PDF
                </a>
              </div>
            ))}
          </div>
        </animated.div>
      </main>
      <Footer />
    </div>
  );
}

export default Documents;