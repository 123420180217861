import React, { useState, useEffect } from 'react';
import { FaPlus } from 'react-icons/fa';
import supabase from '../supabase';
import { useAuth } from '../context/AuthContext';
import Modal from 'react-modal';

Modal.setAppElement('#root');

const CreatePost = ({ isOpen, onRequestClose, fetchPosts, selectedGroup }) => {
  const { currentUser } = useAuth();
  const [content, setContent] = useState('');
  const [category, setCategory] = useState(selectedGroup);
  const [imageFile, setImageFile] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [profile, setProfile] = useState(null);

  useEffect(() => {
    setCategory(selectedGroup);
  }, [selectedGroup]);

  useEffect(() => {
    if (currentUser) {
      const fetchProfile = async () => {
        try {
          const { data, error } = await supabase
            .from('profiles')
            .select('id, first_name, last_name, avatar_url')
            .eq('id', currentUser.id)
            .single();
          
          if (error) {
            console.error('Errore nel recuperare il profilo:', error.message);
          } else {
            setProfile(data);
          }
        } catch (error) {
          console.error('Errore durante il recupero del profilo:', error);
        }
      };

      fetchProfile();
    }
  }, [currentUser]);

  const handleImageChange = (e) => {
    setImageFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!currentUser || !currentUser.id) {
      console.error('L\'utente non è loggato');
      return;
    }

    try {
      let imageUrl = null;
      if (imageFile) {
        const fileExt = imageFile.name.split('.').pop();
        const fileName = `${Date.now()}.${fileExt}`;
        const filePath = `public/${fileName}`;

        const { data: imageData, error: imageError } = await supabase.storage
          .from('post-images')
          .upload(filePath, imageFile, {
            onUploadProgress: (event) => {
              const progress = Math.round((100 * event.loaded) / event.total);
              setUploadProgress(progress);
            }
          });

        if (imageError) {
          console.error('Errore nel caricamento dell\'immagine:', imageError.message);
          return;
        }

        const { data: publicUrlData, error: publicUrlError } = await supabase
          .storage
          .from('post-images')
          .getPublicUrl(filePath);

        if (publicUrlError) {
          console.error('Errore nel recupero dell\'URL pubblico:', publicUrlError.message);
          return;
        }

        imageUrl = publicUrlData.publicUrl;
      }

      const { error: postError } = await supabase
        .from('posts')
        .insert([
          {
            content,
            user_id: currentUser.id,
            category,
            image_url: imageUrl,
            first_name: profile.first_name,
            last_name: profile.last_name,
            avatar_url: profile.avatar_url
          },
        ]);

      if (postError) {
        console.error('Errore nella creazione del post:', postError.message);
      } else {
        setContent('');
        setCategory('');
        setImageFile(null);
        fetchPosts(); // Aggiorna i post dopo la creazione di un nuovo post
        onRequestClose();
      }
    } catch (error) {
      console.error('Errore in handleSubmit:', error);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Crea un Nuovo Post"
      className="fixed inset-0 flex items-center justify-center p-4 z-50"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-40"
    >
      <div className="bg-black bg-opacity-90 p-6 rounded-xl shadow-lg w-full max-w-lg mx-auto space-y-4">
        <h2 className="text-xl font-bold mb-4 text-white">Crea un Nuovo Post</h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <textarea
            value={content}
            onChange={(e) => setContent(e.target.value)}
            placeholder="Scrivi il contenuto del tuo post..."
            className="textarea textarea-bordered w-full text-white font-bold bg-gray-700 border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-teal-500"
            autoComplete="off"
          />
          <select
            value={category}
            onChange={(e) => setCategory(e.target.value)}
            className="input input-bordered w-full text-white bg-gray-700 border border-gray-600 rounded-lg focus:outline-none focus:ring focus:ring-teal-500"
            required
          >
            <option value="" disabled>Seleziona categoria...</option>
            <option value="Allievi Carabinieri">Allievi Carabinieri</option>
            <option value="Marescialli Carabinieri">Marescialli Carabinieri</option>
            <option value="Allievi Finanzieri">Allievi Finanzieri</option>
            <option value="Allievi Agenti Polizia di Stato">Allievi Agenti Polizia di Stato</option>
            <option value="VFI AM">VFI AM</option>
            <option value="VFI MM">VFI MM</option>
            <option value="VFI EI">VFI EI</option>
          </select>
          <input
            type="file"
            onChange={handleImageChange}
            className="file-input file-input-bordered w-full text-white bg-gray-700 border border-gray-600 rounded-lg focus:outline-none focus:ring focus:ring-teal-500"
          />
          {uploadProgress > 0 && (
            <div className="w-full bg-gray-200 rounded-lg">
              <div className="bg-teal-500 text-xs font-medium text-teal-100 text-center p-0.5 leading-none rounded-lg" style={{ width: `${uploadProgress}%` }}>
                {uploadProgress}%
              </div>
            </div>
          )}
          <button
            type="submit"
            className="btn btn-primary bg-teal-500 hover:bg-teal-600 text-white font-bold py-2 px-4 rounded-lg transition duration-300 transform hover:scale-105 shadow-md"
          >
            <FaPlus className="inline mr-2" /> Crea Post
          </button>
          <button
            type="button"
            onClick={onRequestClose}
            className="btn btn-secondary bg-gray-600 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded-lg transition duration-300 transform hover:scale-105 shadow-md"
          >
            Cancella
          </button>
        </form>
      </div>
    </Modal>
  );
};

export default CreatePost;
