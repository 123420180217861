import React, { useState, useEffect } from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { useSpring, animated } from '@react-spring/web';
import quizData from '../pdfs/domande_risposte.json'; // Importa direttamente il file JSON

const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

const PDFQuiz = () => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedAnswers, setSelectedAnswers] = useState(new Array(quizData.length).fill(null));
  const [showResults, setShowResults] = useState(false);
  const [shuffledAnswers, setShuffledAnswers] = useState([]);

  useEffect(() => {
    if (quizData.length > 0) {
      const answers = Object.entries(quizData[currentQuestionIndex].risposte);
      setShuffledAnswers(shuffleArray(answers));
    }
  }, [currentQuestionIndex]);

  const fadeIn = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { duration: 1000 },
  });

  const handleAnswerSelect = (key) => {
    const updatedAnswers = [...selectedAnswers];
    updatedAnswers[currentQuestionIndex] = key;
    setSelectedAnswers(updatedAnswers);
  };

  const handleNextQuestion = () => {
    if (currentQuestionIndex < quizData.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      setShowResults(true);
    }
  };

  const handleStopQuiz = () => {
    setShowResults(true);
  };

  const resetQuiz = () => {
    setSelectedAnswers(new Array(quizData.length).fill(null));
    setCurrentQuestionIndex(0);
    setShowResults(false);
  };

  if (quizData.length === 0) {
    return <div>Loading...</div>;
  }

  const currentQuestion = quizData[currentQuestionIndex];
  const remainingQuestions = quizData.length - currentQuestionIndex - 1;
  const correctAnswer = "A";

  return (
    <div className="min-h-screen flex flex-col bg-gradient-to-b from-gray-800 to-gray-900 text-white font-sans">
      <Navbar />
      <main className="flex-1 container mx-auto mt-5 p-4">
        <animated.div style={fadeIn} className="relative bg-gray-700 p-8 rounded-lg shadow-lg overflow-hidden">
          <div className="absolute inset-0 bg-gradient-to-r from-purple-600 via-pink-500 to-red-500 opacity-30 animate-pulse"></div>
          <h1 className="text-4xl font-bold text-center mb-6" style={{ fontFamily: 'Georgia, serif' }}>Simulatore di Quiz</h1>
          {!showResults ? (
            <div className="relative p-6 rounded-lg bg-gray-800 shadow-md border-2 border-gray-600">
              <div className="absolute top-0 left-0 w-full h-1 bg-gradient-to-r from-green-400 to-blue-400"></div>
              <h3 className="text-xl font-semibold mb-4" style={{ fontFamily: 'Georgia, serif' }}>{currentQuestion.domanda}</h3>
              <ul>
                {shuffledAnswers.map(([key, answer], index) => (
                  <li
                    key={index}
                    onClick={() => handleAnswerSelect(key)}
                    className={`cursor-pointer p-4 mb-2 rounded-lg text-lg font-medium transition-colors duration-300 ${
                      selectedAnswers[currentQuestionIndex] === key ? 
                      (key === correctAnswer ? 'bg-green-500 text-white' : 'bg-red-500 text-white') : 'bg-gray-300 text-black'
                    }`}
                    style={{ fontFamily: 'Georgia, serif' }}
                  >
                    {key}) {answer}
                  </li>
                ))}
              </ul>
              <div className="text-center">
                <button
                  onClick={handleNextQuestion}
                  className="bg-green-500 hover:bg-green-600 text-white p-3 rounded-lg mt-6 transition-all duration-300 transform hover:scale-105"
                  style={{ fontFamily: 'Georgia, serif' }}
                >
                  Avanti
                </button>
                <button
                  onClick={handleStopQuiz}
                  className="bg-red-500 hover:bg-red-600 text-white p-3 rounded-lg mt-6 ml-4 transition-all duration-300 transform hover:scale-105"
                  style={{ fontFamily: 'Georgia, serif' }}
                >
                  Stop
                </button>
              </div>
              <div className="text-center mt-4">
                Domande rimanenti: {remainingQuestions}
              </div>
            </div>
          ) : (
            <div className="mt-10">
              <h2 className="text-3xl font-bold mb-6 text-center" style={{ fontFamily: 'Georgia, serif' }}>Risultati</h2>
              {selectedAnswers.map((selectedAnswer, index) => (
                selectedAnswer !== null && (
                  <div key={index} className="mb-4 p-6 rounded-lg bg-gray-800 shadow-md relative border-2 border-gray-600">
                    <div className="absolute top-0 left-0 w-full h-1 bg-gradient-to-r from-green-400 to-blue-400"></div>
                    <h3 className="text-xl font-semibold" style={{ fontFamily: 'Georgia, serif' }}>{quizData[index].domanda}</h3>
                    <p
                      className={`p-4 mt-2 rounded-lg text-lg font-medium ${
                        selectedAnswer === correctAnswer
                          ? 'bg-green-500 text-white'
                          : 'bg-red-500 text-white'
                      }`}
                      style={{ fontFamily: 'Georgia, serif' }}
                    >
                      La tua risposta: {quizData[index].risposte[selectedAnswer] || 'Nessuna risposta'}
                    </p>
                    {selectedAnswer !== correctAnswer && (
                      <p className="p-4 mt-2 bg-green-500 text-white rounded-lg text-lg font-medium" style={{ fontFamily: 'Georgia, serif' }}>
                        Risposta corretta: {quizData[index].risposte[correctAnswer]}
                      </p>
                    )}
                  </div>
                )
              ))}
              <div className="text-center">
                <button
                  onClick={resetQuiz}
                  className="bg-red-500 hover:bg-red-600 text-white p-3 rounded-lg mt-6 transition-all duration-300 transform hover:scale-105"
                  style={{ fontFamily: 'Georgia, serif' }}
                >
                  Reset
                </button>
              </div>
            </div>
          )}
        </animated.div>
      </main>
      <Footer />
    </div>
  );
};

export default PDFQuiz;
