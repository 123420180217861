import React from 'react';
import { useSpring, animated } from '@react-spring/web';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Sidebar from '../components/Sidebar'; // Import Sidebar
import { useAuth } from '../context/AuthContext'; // Import useAuth

const sections = [
  {
    id: 1,
    title: 'Documentazione Generale',
    description: 'Scarica la documentazione generale per il concorso.',
    pdfUrl: 'https://example.com/documentazione-generale.pdf',
    imgUrl: 'https://m.media-amazon.com/images/I/61kW-N1saHL._AC_UL320_.jpg',
  },
  {
    id: 2,
    title: 'Lista RAV',
    description: 'Scarica la lista RAV aggiornata.',
    pdfUrl: 'https://example.com/lista-rav.pdf',
    imgUrl: 'https://m.media-amazon.com/images/I/61PAtI13FNL._AC_UL320_.jpg',
  },
  {
    id: 3,
    title: 'Regolamenti e Procedure',
    description: 'Scarica i regolamenti e le procedure operative.',
    pdfUrl: 'https://example.com/regolamenti-procedure.pdf',
    imgUrl: 'https://m.media-amazon.com/images/I/61FlW4IxRvL._AC_UL320_.jpg',
  },
];

function ListaRav() {
  const { currentUser } = useAuth(); // Use useAuth to get the currentUser
  const fadeIn = useSpring({
    from: { opacity: 0, transform: 'translate3d(0, -20px, 0)' },
    to: { opacity: 1, transform: 'translate3d(0, 0, 0)' },
    config: { duration: 1000 },
  });

  return (
    <div className="min-h-screen flex flex-col lg:flex-row bg-gray-900 text-white">
      {currentUser && <Sidebar currentUser={currentUser} />}
      <div className="flex-1 lg:ml-72">
        <Navbar />
        <main className="flex-1 container mx-auto mt-5 p-4">
          <div className="relative bg-gray-800 p-8 rounded-lg shadow-lg overflow-hidden">
            <animated.div style={fadeIn}>
              <h1 className="text-4xl font-bold text-center text-blue-500 transition duration-500 mb-6">
                Sezioni di Download
              </h1>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                {sections.map((section) => (
                  <div key={section.id} className="relative bg-gray-700 p-8 rounded-lg shadow-lg overflow-hidden">
                    <h2 className="text-2xl font-bold mb-4">{section.title}</h2>
                    <img src={section.imgUrl} alt={section.title} className="w-full h-48 object-cover mb-4 rounded" />
                    <p className="text-lg mb-4">{section.description}</p>
                    <a
                      href={section.pdfUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="block text-center text-lg text-blue-500 underline mt-4"
                    >
                      Scarica PDF
                    </a>
                  </div>
                ))}
              </div>
            </animated.div>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
}

export default ListaRav;
