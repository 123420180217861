import React, { useEffect, useState } from 'react';
import { FaHome, FaBook, FaList, FaRobot, FaUserCircle, FaEdit, FaSignOutAlt, FaCrown, FaLifeRing, FaTimes } from 'react-icons/fa';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import supabase from '../supabase';

const Sidebar = ({ isOpen, toggleSidebar }) => {
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();
  const [userPoints, setUserPoints] = useState(0);

  useEffect(() => {
    if (currentUser) {
      const fetchUserPoints = async () => {
        try {
          const { data, error } = await supabase
            .from('user_points')
            .select('points')
            .eq('user_id', currentUser.id)
            .maybeSingle();

          if (error) {
            if (error.code === 'PGRST116') {
              console.warn('Nessun punto trovato per questo utente.');
              setUserPoints(0); // Set points to 0 if no points are found
            } else {
              console.error('Error fetching user points:', error);
            }
          } else if (data) {
            setUserPoints(data.points);
          } else {
            console.warn('Nessun punto trovato per questo utente.');
            setUserPoints(0); // Set points to 0 if no points are found
          }
        } catch (error) {
          console.error('Error during fetch user points:', error);
        }
      };
      fetchUserPoints();
    }
  }, [currentUser]);

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  const isVIP = userPoints >= 1000;

  return (
    <div className={`bg-gradient-to-b from-gray-800 to-gray-900 text-white shadow-2xl w-64 p-6 fixed inset-y-0 left-0 transform ${isOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 lg:relative lg:translate-x-0`}>
      <button onClick={toggleSidebar} className="lg:hidden absolute top-4 right-4 text-white text-2xl">
        <FaTimes />
      </button>
      <div className="bg-gray-900 p-6 rounded-lg shadow-lg mb-6 text-center">
        {currentUser ? (
          <>
            <div className="p-4 rounded-lg shadow-md flex flex-col items-center bg-gray-800 transform transition duration-500 hover:scale-105">
              <img 
                src={currentUser.avatar_url || 'https://via.placeholder.com/150'} 
                alt="Profile" 
                className="rounded-full w-24 h-24 mb-4 border-4 border-teal-500 shadow-lg transform transition duration-500 hover:rotate-6 hover:scale-110" 
              />
              <h2 className="font-bold text-2xl text-teal-400 flex items-center">
                {currentUser.first_name} {currentUser.last_name} {isVIP && <FaCrown className="ml-2 text-yellow-500" />}
              </h2>
              <p className="text-gray-400">@{currentUser.username}</p>
              <button
                onClick={() => navigate('/edit-profile')}
                className="mt-4 bg-teal-500 hover:bg-teal-600 text-white font-bold py-2 px-4 rounded-lg transition duration-300 transform hover:scale-105 shadow-md flex items-center"
              >
                <FaEdit className="mr-2" /> Modifica Profilo
              </button>
              <button
                onClick={handleLogout}
                className="mt-4 bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded-lg transition duration-300 transform hover:scale-105 shadow-md flex items-center"
              >
                <FaSignOutAlt className="mr-2" /> Logout
              </button>
            </div>
            <div className="mt-6 bg-gray-800 p-4 rounded-lg shadow-lg text-center">
              <h3 className="text-xl font-bold text-teal-400">I tuoi punti</h3>
              <p className="text-2xl font-semibold text-white">{userPoints}</p>
              {isVIP && <p className="text-teal-400 font-bold mt-2">Utente VIP</p>}
            </div>
          </>
        ) : (
          <FaUserCircle className="text-6xl text-gray-500 mx-auto" />
        )}
      </div>
      <nav className="mt-8 space-y-4">
        <ul className="space-y-4">
          <li className="flex items-center p-2 text-gray-300 hover:bg-gray-700 rounded-lg cursor-pointer transition duration-300 transform hover:translate-x-2">
            <Link to="/dashboard" className="flex items-center">
              <FaHome className="mr-2 text-teal-400" /> <span className="font-semibold">Home</span>
            </Link>
          </li>
          <li className="flex items-center p-2 text-gray-300 hover:bg-gray-700 rounded-lg cursor-pointer transition duration-300 transform hover:translate-x-2">
            <Link to="/manuali" className="flex items-center">
              <FaBook className="mr-2 text-teal-400" /> <span className="font-semibold">Manuali</span>
            </Link>
          </li>
          <li className="flex items-center p-2 text-gray-300 hover:bg-gray-700 rounded-lg cursor-pointer transition duration-300 transform hover:translate-x-2">
            <Link to="/listarav" className="flex items-center">
              <FaList className="mr-2 text-teal-400" /> <span className="font-semibold">Lista RAV</span>
            </Link>
          </li>
          <li className="flex items-center p-2 text-gray-300 hover:bg-gray-700 rounded-lg cursor-pointer transition duration-300 transform hover:translate-x-2">
            <FaRobot className="mr-2 text-teal-400" /> <span className="font-semibold">Chatbot</span>
          </li>
          <li className="flex items-center p-2 text-gray-300 hover:bg-gray-700 rounded-lg cursor-pointer transition duration-300 transform hover:translate-x-2">
            <Link to="/support" className="flex items-center">
              <FaLifeRing className="mr-2 text-teal-400" /> <span className="font-semibold">Supporto</span>
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;
