import React, { useState } from 'react';
import supabase from '../supabase';
import { FaEdit, FaTrash } from 'react-icons/fa';
import avatar from '../images/bot.png'; // Percorso corretto per l'importazione dell'immagine

const Comment = ({ comment, currentUser, fetchPosts, handleDeleteComment, handleEditComment }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedContent, setEditedContent] = useState(comment.content);

  const handleEdit = () => {
    handleEditComment(comment.id, editedContent);
    setIsEditing(false);
  };

  const avatarUrl = comment.avatar_url || avatar;

  return (
    <div className="bg-gray-700 p-4 rounded-lg shadow-md mb-2">
      <div className="flex items-center mb-2">
        <img
          src={avatarUrl}
          alt="User Avatar"
          className="w-8 h-8 rounded-full border-2 border-teal-500 shadow-lg"
        />
        <div className="ml-2">
          <p className="text-sm font-bold text-white">{comment.first_name} {comment.last_name}</p>
          <p className="text-xs text-gray-400">{new Date(comment.created_at).toLocaleString()}</p>
        </div>
        {currentUser && currentUser.id === comment.user_id && (
          <div className="ml-auto flex space-x-2">
            <button onClick={() => setIsEditing(true)} className="text-blue-500 hover:text-blue-700"><FaEdit /></button>
            <button onClick={() => handleDeleteComment(comment.id)} className="text-red-500 hover:text-red-700"><FaTrash /></button>
          </div>
        )}
      </div>
      {isEditing ? (
        <div>
          <textarea
            value={editedContent}
            onChange={(e) => setEditedContent(e.target.value)}
            className="textarea textarea-bordered w-full text-white font-bold bg-gray-600 border border-gray-500 rounded-lg focus:outline-none focus:ring-2 focus:ring-teal-500 mb-2"
          />
          <button onClick={handleEdit} className="btn btn-primary bg-teal-500 hover:bg-teal-600 text-white font-bold py-1 px-2 rounded-lg transition duration-300 transform hover:scale-105 shadow-md">Salva</button>
        </div>
      ) : (
        <p className="text-white">{comment.content}</p>
      )}
    </div>
  );
};

export default Comment;
