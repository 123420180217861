import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import supabase from '../supabase';
import { useAuth } from '../context/AuthContext';

const EditProfile = () => {
  const { currentUser, updateUser } = useAuth();
  const navigate = useNavigate();
  const [avatarUrl, setAvatarUrl] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [bio, setBio] = useState('');
  const [instagram, setInstagram] = useState('');
  const [tiktok, setTiktok] = useState('');
  const [uploading, setUploading] = useState(false);

  useEffect(() => {
    if (currentUser) {
      setAvatarUrl(currentUser.avatar_url);
      setFirstName(currentUser.first_name);
      setLastName(currentUser.last_name);
      setBio(currentUser.bio);
      setInstagram(currentUser.instagram);
      setTiktok(currentUser.tiktok);
    }
  }, [currentUser]);

  const uploadAvatar = async (event) => {
    try {
      setUploading(true);

      if (!event.target.files || event.target.files.length === 0) {
        throw new Error('You must select an image to upload.');
      }

      const file = event.target.files[0];
      const filePath = `${currentUser.id}/${file.name}`;

      const { data: uploadData, error: uploadError } = await supabase.storage
        .from('avatars')
        .upload(filePath, file, { upsert: true });

      if (uploadError) {
        throw uploadError;
      }

      const { data: publicURLData, error: publicURLError } = await supabase
        .storage
        .from('avatars')
        .getPublicUrl(filePath);

      if (publicURLError) {
        throw publicURLError;
      }

      const publicURL = publicURLData.publicUrl;

      if (!publicURL) {
        throw new Error('Failed to retrieve public URL');
      }

      const { error: updateError } = await supabase
        .from('profiles')
        .update({ avatar_url: publicURL })
        .eq('id', currentUser.id);

      if (updateError) {
        throw updateError;
      }

      setAvatarUrl(publicURL);
      updateUser({ avatar_url: publicURL });

    } catch (error) {
      console.error('Error uploading avatar:', error.message);
      alert(error.message);
    } finally {
      setUploading(false);
    }
  };

  const updateProfile = async () => {
    try {
      const { error } = await supabase
        .from('profiles')
        .update({ first_name: firstName, last_name: lastName, bio, instagram, tiktok })
        .eq('id', currentUser.id);

      if (error) {
        throw error;
      }

      updateUser({ first_name: firstName, last_name: lastName, bio, instagram, tiktok });
      alert('Profile updated successfully');
      navigate('/dashboard');
    } catch (error) {
      console.error('Error updating profile:', error.message);
      alert(error.message);
    }
  };

  return (
    <div className="flex flex-col items-center mb-6">
      <img
        src={avatarUrl || 'https://via.placeholder.com/150'}
        alt="User Avatar"
        className="w-24 h-24 rounded-full border-4 border-teal-500 shadow-lg"
      />
      <div className="mt-4 text-center">
        <h2 className="text-2xl font-bold text-white">Modifica Profilo</h2>
        <div className="mt-2">
          <label className="btn btn-primary bg-teal-500 hover:bg-teal-600 text-white rounded-lg cursor-pointer">
            {uploading ? 'Uploading...' : 'Carica Foto Profilo'}
            <input
              type="file"
              accept="image/*"
              onChange={uploadAvatar}
              className="hidden"
              disabled={uploading}
            />
          </label>
        </div>
        <div className="mt-4">
          <input
            type="text"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            placeholder="Nome"
            className="input input-bordered w-full text-white font-bold bg-gray-700 border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-teal-500"
          />
        </div>
        <div className="mt-4">
          <input
            type="text"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            placeholder="Cognome"
            className="input input-bordered w-full text-white font-bold bg-gray-700 border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-teal-500"
          />
        </div>
        <div className="mt-4">
          <textarea
            value={bio}
            onChange={(e) => setBio(e.target.value)}
            placeholder="Inserisci una bio"
            className="textarea textarea-bordered w-full text-white font-bold bg-gray-700 border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-teal-500"
          />
        </div>
        <div className="mt-4">
          <input
            type="text"
            value={instagram}
            onChange={(e) => setInstagram(e.target.value)}
            placeholder="Link Instagram"
            className="input input-bordered w-full text-white font-bold bg-gray-700 border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-teal-500"
          />
        </div>
        <div className="mt-4">
          <input
            type="text"
            value={tiktok}
            onChange={(e) => setTiktok(e.target.value)}
            placeholder="Link TikTok"
            className="input input-bordered w-full text-white font-bold bg-gray-700 border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-teal-500"
          />
        </div>
        <div className="mt-4">
          <button
            onClick={updateProfile}
            className="btn btn-primary bg-teal-500 hover:bg-teal-600 text-white p-2 rounded-lg transition duration-300 transform hover:scale-105 shadow-md"
          >
            Aggiorna Profilo
          </button>
        </div>
        <div className="mt-4">
          <button
            onClick={() => navigate('/dashboard')}
            className="btn btn-secondary bg-gray-500 hover:bg-gray-600 text-white p-2 rounded-lg transition duration-300 transform hover:scale-105 shadow-md"
          >
            Torna alla Dashboard
          </button>
        </div>
      </div>
    </div>
  );
};

export default EditProfile;
