import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faSignInAlt, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { motion } from 'framer-motion';
import { Link, useNavigate } from 'react-router-dom'; 
import { useAuth } from '../context/AuthContext'; 

function Navbar() {
  const [hoveredItem, setHoveredItem] = useState(null);
  const { currentUser, logout } = useAuth(); 
  const navigate = useNavigate(); 

  const handleMouseEnter = (index) => {
    setHoveredItem(index);
  };

  const handleMouseLeave = () => {
    setHoveredItem(null);
  };

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/login'); 
    } catch (error) {
      console.error('Errore durante il logout:', error);
    }
  };

  return (
    <nav className="bg-gray-800 text-white p-4 shadow-lg">
      <div className="container mx-auto flex justify-between items-center">
        <Link to="/" className="font-bold text-2xl">Concorsinfo</Link> 
        <ul className="flex space-x-4">
          <Link to="/manuali" className="hover:text-gray-300 transition duration-300"> 
            <NavItem
              icon={faBook}
              text="Manuali"
              index={0}
              hoveredItem={hoveredItem}
              handleMouseEnter={handleMouseEnter}
              handleMouseLeave={handleMouseLeave}
            />
          </Link>
          {currentUser ? (
            <motion.li
              className="hover:text-gray-300 transition duration-300 cursor-pointer"
              onClick={handleLogout}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              onMouseEnter={() => handleMouseEnter(1)}
              onMouseLeave={handleMouseLeave}
            >
              <FontAwesomeIcon icon={faSignOutAlt} className={`mr-2 ${hoveredItem === 1 && 'text-yellow-500'}`} />
              <span className="font-bold">Logout</span>
            </motion.li>
          ) : (
            <Link to="/login" className="hover:text-gray-300 transition duration-300"> 
              <NavItem
                icon={faSignInAlt}
                text="Login"
                index={1}
                hoveredItem={hoveredItem}
                handleMouseEnter={handleMouseEnter}
                handleMouseLeave={handleMouseLeave}
              />
            </Link>
          )}
        </ul>
      </div>
    </nav>
  );
}

const NavItem = ({ icon, text, index, hoveredItem, handleMouseEnter, handleMouseLeave }) => {
  const isHovered = hoveredItem === index;

  return (
    <motion.li
      className="transition duration-300 ease-in-out relative"
      whileHover={{ scale: 1.1 }}
      whileTap={{ scale: 0.9 }}
      onMouseEnter={() => handleMouseEnter(index)}
      onMouseLeave={handleMouseLeave}
    >
      <FontAwesomeIcon icon={icon} className={`mr-2 ${isHovered && 'text-yellow-500'}`} />
      <span className="font-bold">{text}</span>
      {isHovered && (
        <div className="absolute bg-gray-900 text-white px-2 py-1 rounded-md shadow-md mt-2">
          Contenuto extra per {text}
        </div>
      )}
    </motion.li>
  );
};

export default Navbar;
