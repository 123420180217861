import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import supabase from '../supabase';
import { FaInstagram, FaTiktok } from 'react-icons/fa';

const UserProfilePage = () => {
  const { id } = useParams();
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      const { data, error } = await supabase
        .from('users')
        .select('id, email, avatar_url, bio, instagram, tiktok, first_name, last_name')
        .eq('id', id)
        .single();
        
      if (error) {
        console.error('Error fetching user:', error.message);
      } else {
        setUser(data);
      }
    };

    fetchUser();
  }, [id]);

  if (!user) return <div>Loading...</div>;

  return (
    <div className="min-h-screen flex flex-col items-center bg-gray-900 text-white p-4">
      <img
        src={user.avatar_url || 'https://avatars.dicebear.com/api/avataaars/react.svg?mood[]=happy'}
        alt="User Avatar"
        className="w-24 h-24 rounded-full border-4 border-teal-500 shadow-lg"
      />
      <h2 className="text-2xl font-bold mt-4">{user.first_name} {user.last_name}</h2>
      <p className="mt-2 text-center">{user.bio}</p>
      <div className="mt-4 flex space-x-4">
        {user.instagram && (
          <a href={user.instagram} target="_blank" rel="noopener noreferrer" className="text-white hover:text-teal-500">
            <FaInstagram size={24} />
          </a>
        )}
        {user.tiktok && (
          <a href={user.tiktok} target="_blank" rel="noopener noreferrer" className="text-white hover:text-teal-500">
            <FaTiktok size={24} />
          </a>
        )}
      </div>
    </div>
  );
};

export default UserProfilePage;
