import React, { useState, useEffect } from 'react';
import supabase from '../supabase';
import Post from './Post';

const PostList = ({ currentUser, selectedGroup, fetchPosts, currentPage, setCurrentPage, totalPosts, postsPerPage, handleDeletePost, handleEditPost }) => {
  const [posts, setPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  
  const totalPages = Math.ceil(totalPosts / postsPerPage);

  useEffect(() => {
    const fetchPostsForGroup = async () => {
      setIsLoading(true);
      setError(null);

      try {
        let query = supabase
          .from('posts')
          .select('*, comments(*), reactions(*)')
          .order('created_at', { ascending: false })
          .range((currentPage - 1) * postsPerPage, currentPage * postsPerPage - 1);

        if (selectedGroup && selectedGroup !== 'all') {
          query = query.eq('category', selectedGroup);
        }

        const { data, error } = await query;

        if (error) {
          console.error('Errore nel recupero dei post:', error);
          setError(error);
        } else {
          setPosts(data);
        }
      } catch (error) {
        console.error('Errore inatteso nel recupero dei post:', error);
        setError(error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchPostsForGroup();
  }, [selectedGroup, currentPage, postsPerPage]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderPaginationButtons = () => {
    const buttons = [];
    for (let i = 1; i <= totalPages; i++) {
      buttons.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={`btn ${currentPage === i ? 'btn-primary' : 'btn-secondary'} mx-1`}
        >
          {i}
        </button>
      );
    }
    return buttons;
  };

  return (
    <div className="container mx-auto p-4">
      {isLoading ? (
        <p>Caricamento in corso...</p>
      ) : error ? (
        <p>Errore: {error.message}</p>
      ) : posts.length > 0 ? (
        <>
          {posts.map(post => (
            <Post
              key={post.id}
              post={post}
              currentUser={currentUser}
              fetchPosts={() => fetchPosts(selectedGroup, currentPage)}
              handleDeletePost={handleDeletePost} // Passa handleDeletePost come prop
              handleEditPost={handleEditPost} // Passa handleEditPost come prop
            />
          ))}
          <div className="flex justify-center mt-4">
            <button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className="btn btn-secondary mx-1"
            >
              Indietro
            </button>
            {renderPaginationButtons()}
            <button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className="btn btn-secondary mx-1"
            >
              Avanti
            </button>
          </div>
        </>
      ) : (
        <p className="text-gray-500">Nessun post disponibile per questo gruppo.</p>
      )}
    </div>
  );
};

export default PostList;
