import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import Sidebar from '../components/Sidebar';
import CreatePost from '../components/CreatePost';
import PostList from '../components/PostList';
import UserNavbar from '../components/UserNavbar';
import { FaSpinner, FaShieldAlt, FaUserSecret, FaLandmark, FaUserTie, FaFighterJet, FaShip, FaInstagram } from 'react-icons/fa';
import supabase from '../supabase';

const Dashboard = () => {
  const { currentUser, loading } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const [selectedGroup, setSelectedGroup] = useState('all');
  const [posts, setPosts] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const postsPerPage = 10;

  const fetchPosts = async (group = 'all', page = 1) => {
    setIsLoading(true);
    try {
      let query = supabase
        .from('posts')
        .select(`id, content, image_url, created_at, user_id, category, profiles(first_name, last_name, avatar_url)`)
        .order('created_at', { ascending: false })
        .range((page - 1) * postsPerPage, page * postsPerPage - 1);

      if (group && group !== 'all') {
        query = query.eq('category', group);
      }

      const { data, error } = await query;

      if (error) {
        console.error('Errore nel recupero dei post:', error);
      } else {
        setPosts(data);
      }
    } catch (error) {
      console.error('Errore inatteso nel recupero dei post:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeletePost = async (postId) => {
    try {
      const { error } = await supabase
        .from('posts')
        .delete()
        .eq('id', postId);
      if (error) {
        console.error('Errore nella cancellazione del post:', error);
      } else {
        fetchPosts(selectedGroup, currentPage);
      }
    } catch (error) {
      console.error('Errore inatteso nella cancellazione del post:', error);
    }
  };

  const handleEditPost = async (postId, content) => {
    try {
      const { error } = await supabase
        .from('posts')
        .update({ content })
        .eq('id', postId);
      if (error) {
        console.error('Errore nella modifica del post:', error);
      } else {
        fetchPosts(selectedGroup, currentPage);
      }
    } catch (error) {
      console.error('Errore inatteso nella modifica del post:', error);
    }
  };

  useEffect(() => {
    if (currentUser) {
      fetchPosts(selectedGroup, currentPage);
    }
  }, [currentUser, selectedGroup, currentPage]);

  if (loading) {
    return <div className="flex justify-center items-center min-h-screen bg-gray-900 text-white">Caricamento...</div>;
  }

  if (!currentUser) {
    return (
      <div className="flex justify-center items-center min-h-screen bg-gray-900 text-white">
        <p>Devi essere autenticato per vedere questa pagina.</p>
      </div>
    );
  }

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="flex flex-col lg:flex-row min-h-screen bg-gray-900 text-white">
      <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
      <div className={`flex-1 transition-all duration-300 ${isSidebarOpen ? 'ml-64' : 'ml-0'}`}>
        <UserNavbar toggleSidebar={toggleSidebar} />
        <main className="p-4 lg:p-6 mt-16 flex flex-col space-y-6">
          <div className="w-full">
            <button 
              onClick={() => setIsModalOpen(true)} 
              className="bg-teal-500 text-white p-2 lg:p-4 rounded-md mb-4 shadow-lg transform transition duration-300 hover:scale-105 w-full"
            >
              Crea un Post
            </button>
            <CreatePost 
              isOpen={isModalOpen} 
              onRequestClose={() => setIsModalOpen(false)} 
              fetchPosts={() => fetchPosts(selectedGroup, currentPage)}
              selectedGroup={selectedGroup} // Passa il gruppo selezionato
            />
            <section className="mb-8">
              <h2 className="text-xl lg:text-2xl font-bold mb-4">Feeds</h2>
              {isLoading ? (
                <div className="flex justify-center items-center h-64">
                  <FaSpinner className="animate-spin text-4xl text-teal-500" />
                </div>
              ) : (
                <PostList 
                  currentUser={currentUser} 
                  selectedGroup={selectedGroup} 
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  totalPosts={posts.length}
                  postsPerPage={postsPerPage}
                  fetchPosts={fetchPosts} // Passa fetchPosts come prop
                  handleDeletePost={handleDeletePost} // Passa handleDeletePost come prop
                  handleEditPost={handleEditPost} // Passa handleEditPost come prop
                />
              )}
            </section>
          </div>
          <aside className="w-full space-y-8">
            <section className="bg-gray-800 p-4 rounded-lg shadow-lg">
              <h2 className="text-xl font-bold mb-4">Gruppi</h2>
              <div className="grid grid-cols-2 gap-4">
                <button onClick={() => setSelectedGroup('Allievi Carabinieri')} className={`px-2 py-2 rounded-lg ${selectedGroup === 'Allievi Carabinieri' ? 'bg-teal-500' : 'bg-gray-700'} text-white`}>
                  <FaShieldAlt className="h-6 w-6 mx-auto" />
                  <p className="text-center text-xs mt-2">Allievi Carabinieri</p>
                </button>
                <button onClick={() => setSelectedGroup('Marescialli Carabinieri')} className={`px-2 py-2 rounded-lg ${selectedGroup === 'Marescialli Carabinieri' ? 'bg-teal-500' : 'bg-gray-700'} text-white`}>
                  <FaUserSecret className="h-6 w-6 mx-auto" />
                  <p className="text-center text-xs mt-2">Marescialli Carabinieri</p>
                </button>
                <button onClick={() => setSelectedGroup('Allievi Finanzieri')} className={`px-2 py-2 rounded-lg ${selectedGroup === 'Allievi Finanzieri' ? 'bg-teal-500' : 'bg-gray-700'} text-white`}>
                  <FaLandmark className="h-6 w-6 mx-auto" />
                  <p className="text-center text-xs mt-2">Allievi Finanzieri</p>
                </button>
                <button onClick={() => setSelectedGroup('Allievi Agenti Polizia di Stato')} className={`px-2 py-2 rounded-lg ${selectedGroup === 'Allievi Agenti Polizia di Stato' ? 'bg-teal-500' : 'bg-gray-700'} text-white`}>
                  <FaUserTie className="h-6 w-6 mx-auto" />
                  <p className="text-center text-xs mt-2">Allievi Agenti Polizia di Stato</p>
                </button>
                <button onClick={() => setSelectedGroup('VFI AM')} className={`px-2 py-2 rounded-lg ${selectedGroup === 'VFI AM' ? 'bg-teal-500' : 'bg-gray-700'} text-white`}>
                  <FaFighterJet className="h-6 w-6 mx-auto" />
                  <p className="text-center text-xs mt-2">VFI AM</p>
                </button>
                <button onClick={() => setSelectedGroup('VFI MM')} className={`px-2 py-2 rounded-lg ${selectedGroup === 'VFI MM' ? 'bg-teal-500' : 'bg-gray-700'} text-white`}>
                  <FaShip className="h-6 w-6 mx-auto" />
                  <p className="text-center text-xs mt-2">VFI MM</p>
                </button>
              </div>
            </section>
            <section className="bg-gray-800 p-4 rounded-lg shadow-lg">
              <h2 className="text-xl font-bold mb-4">Seguici</h2>
              <div className="space-y-4">
                <a href="https://www.instagram.com/concorsinfo_com?igsh=YnA3MzUzY2ZkZ3B5" target="_blank" rel="noopener noreferrer" className="flex items-center space-x-4 text-white">
                  <FaInstagram className="w-6 h-6" />
                  <p className="text-sm">@concorsinfo</p>
                </a>
              </div>
            </section>
            <section className="bg-gray-800 p-4 rounded-lg shadow-lg">
              <h2 className="text-xl font-bold mb-4">Come Usare</h2>
              <div className="text-gray-400 text-sm">
                <p>Benvenuto nella nostra piattaforma sociale! Qui puoi condividere i tuoi pensieri, unirti ai gruppi e seguire persone interessanti. Inizia creando il tuo primo post, unisciti ai gruppi che corrispondono ai tuoi interessi e non dimenticare di seguirci su Instagram per aggiornamenti!</p>
              </div>
            </section>
          </aside>
        </main>
      </div>
    </div>
  );
};

export default Dashboard;
