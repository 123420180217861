import React, { useState } from 'react';
import Modal from 'react-modal';
import { FaEdit, FaTrash } from 'react-icons/fa';
import supabase from '../supabase';
import Comment from './Comment';
import Reaction from './Reaction';
import avatar from '../images/bot.png'; // Percorso corretto per l'importazione dell'immagine

Modal.setAppElement('#root');

const Post = ({ post, currentUser, fetchPosts, handleDeletePost, handleEditPost }) => {
  const [showComments, setShowComments] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editedContent, setEditedContent] = useState(post.content);
  const [imageError, setImageError] = useState(false);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);

  const avatarUrl = post.avatar_url || avatar;

  const handleCommentSubmit = async (content) => {
    if (!content) return;

    const { data: userData, error: userError } = await supabase
      .from('profiles')
      .select('first_name, last_name, avatar_url')
      .eq('id', currentUser.id)
      .single();

    if (userError) {
      console.error('Errore nel recuperare i dati dell\'utente:', userError);
      return;
    }

    const { error } = await supabase
      .from('comments')
      .insert([{ 
        content, 
        post_id: post.id, 
        user_id: currentUser.id,
        first_name: userData.first_name,
        last_name: userData.last_name,
        avatar_url: userData.avatar_url 
      }]);
    if (error) {
      console.error('Errore nella creazione del commento:', error);
    } else {
      fetchPosts();
    }
  };

  const handleEdit = () => {
    handleEditPost(post.id, editedContent);
    setIsEditing(false);
  };

  const handleDeleteComment = async (commentId) => {
    const { error } = await supabase
      .from('comments')
      .delete()
      .eq('id', commentId);

    if (error) {
      console.error('Errore nella cancellazione del commento:', error);
    } else {
      fetchPosts();
    }
  };

  const handleEditComment = async (commentId, content) => {
    const { error } = await supabase
      .from('comments')
      .update({ content })
      .eq('id', commentId);

    if (error) {
      console.error('Errore nella modifica del commento:', error);
    } else {
      fetchPosts();
    }
  };

  const handleImageError = () => {
    setImageError(true);
  };

  const openImageModal = () => {
    setIsImageModalOpen(true);
  };

  const closeImageModal = () => {
    setIsImageModalOpen(false);
  };

  return (
    <div className="bg-gray-800 p-6 rounded-xl shadow-lg mb-6 w-full">
      <div className="flex items-center mb-4">
        <img
          src={avatarUrl}
          alt="User Avatar"
          className="w-12 h-12 rounded-full border-2 border-teal-500 shadow-lg"
        />
        <div className="ml-4">
          <p className="text-sm font-bold text-white">{post.first_name} {post.last_name}</p>
          <p className="text-xs text-gray-500">{new Date(post.created_at).toLocaleString()}</p>
        </div>
        {currentUser && currentUser.id === post.user_id && (
          <div className="ml-auto flex space-x-2">
            <button onClick={() => setIsEditing(true)} className="text-blue-500 hover:text-blue-700"><FaEdit /></button>
            <button onClick={() => handleDeletePost(post.id)} className="text-red-500 hover:text-red-700"><FaTrash /></button>
          </div>
        )}
      </div>
      {isEditing ? (
        <div>
          <textarea
            value={editedContent}
            onChange={(e) => setEditedContent(e.target.value)}
            className="textarea textarea-bordered w-full text-white font-bold bg-gray-700 border border-gray-600 rounded-lg focus:outline-none focus:ring-2 focus:ring-teal-500 mb-4"
          />
          <button onClick={handleEdit} className="btn btn-primary bg-teal-500 hover:bg-teal-600 text-white font-bold py-2 px-4 rounded-lg transition duration-300 transform hover:scale-105 shadow-md">Salva</button>
        </div>
      ) : (
        <p className="mb-4 text-white">{post.content}</p>
      )}
      {!imageError && post.image_url && (
        <div className="mb-4">
          <img
            src={post.image_url}
            alt="Post Image"
            className="rounded-lg shadow-lg w-full h-auto cursor-pointer"
            style={{ maxWidth: '300px', maxHeight: '300px' }} // Ridimensionamento dell'immagine
            onError={handleImageError}
            onClick={openImageModal}
          />
          <Modal
            isOpen={isImageModalOpen}
            onRequestClose={closeImageModal}
            contentLabel="Post Image"
            className="Modal"
            overlayClassName="Overlay"
          >
            <img src={post.image_url} alt="Post Image" className="w-full h-auto" />
          </Modal>
        </div>
      )}
      {imageError && (
        <div className="mb-4">
          <img
            src="https://via.placeholder.com/300"
            alt="Fallback Image"
            className="rounded-lg shadow-lg w-full h-auto"
          />
          <p className="text-xs text-red-500">Immagine non disponibile</p>
        </div>
      )}
      <Reaction postId={post.id} currentUser={currentUser} fetchPosts={fetchPosts} reactions={post.reactions} />
      <div className="mt-4">
        <button
          onClick={() => setShowComments(!showComments)}
          className="btn btn-secondary bg-gray-600 text-white py-2 px-4 rounded-lg transition duration-300 transform hover:scale-105"
        >
          {showComments ? 'Nascondi Commenti' : 'Mostra Commenti'}
        </button>
        {showComments && (
          <>
            <h3 className="text-lg font-bold mb-2 text-white">Commenti</h3>
            {post.comments && post.comments.map((comment) => (
              <Comment
                key={comment.id}
                comment={comment}
                currentUser={currentUser}
                fetchPosts={fetchPosts}
                handleDeleteComment={handleDeleteComment}
                handleEditComment={handleEditComment}
              />
            ))}
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleCommentSubmit(e.target.commentContent.value);
              }}
              className="flex mt-2 w-full"
            >
              <input
                type="text"
                name="commentContent"
                className="input input-bordered w-full text-gray-900 bg-gray-100 border border-gray-300 rounded-lg focus:outline-none focus:ring focus:ring-teal-500"
                placeholder="Scrivi un commento..."
              />
              <button
                type="submit"
                className="btn btn-primary ml-2 bg-teal-500 hover:bg-teal-600 text-white font-bold py-2 px-4 rounded-lg transition duration-300 transform hover:scale-105"
              >
                Invia
              </button>
            </form>
          </>
        )}
      </div>
    </div>
  );
};

export default Post;
