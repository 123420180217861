import React, { useEffect } from 'react';
import supabase from '../supabase';
import stringSimilarity from 'string-similarity';

const responses = [
    { input: "ciao", output: "Ciao! Come posso aiutarti oggi?" },
    { input: "come stai?", output: "Sto bene, grazie! Sono solo un chatbot, quindi non ho sentimenti, ma sono qui per aiutarti!" },
    { input: "chi sei?", output: "Sono un chatbot creato per rispondere alle tue domande. Chiedimi qualsiasi cosa!" },
    { input: "arrivederci", output: "Arrivederci! Se hai altre domande in futuro, non esitare a chiedere." },
    { input: "dove e quando bisogna presentarsi per il concorso in marina militare?", output: "Bisogna presentarsi alle ore 6:30 presso la caserma in via Umberto Cagni nr 2." },
    { input: "che documentazione bisogna presentare per il concorso in marina militare?", output: "Bisogna presentarsi con:\n- esami di laboratorio, eseguiti presso struttura sanitaria accreditata SSN, in data non anteriore a 3 mesi rispetto alla data di convocazione:\nemocromo;\nVES;\nglicemia;\ncreatininemia;\ntrigliceridemia;\ncolesterolemia totale ed HDL; bilirubinemia diretta e indiretta; gamma GT;\ntransaminasemia (GOT e GPT);\nanalisi delle urine con esame del sedimento;\nmarkers virali: anti HAV (IgM ed IgG), Hbs Ag (solo nel caso di certificata, mancata effettuazione della vaccinazione antiHBV), anti HBs e anti HCV;\nricerca anticorpi per HIV;\nTSH\ntest intradermico Mantoux (TST).\n- certificato di stato di buona salute non anteriore a sei mesi rispetto alla data di convocazione (allegato C)\n- per le concorrenti di sesso femminile, referto di ecografia pelvica eseguita presso struttura accreditata SSN in data non anteriore a sei mesi rispetto alla data di convocazione e referto del test di gravidanza eseguito presso SSN in data non anteriore a 5 giorni rispetto alla data di convocazione." },
    { input: "con la CSU si saltano le visite mediche?", output: "Non tutte, si sarà sottoposti alla visita medica generale, prelievo del sangue e drug test." },
    { input: "in cosa consiste la prova di logica in marina militare?", output: "La prova di logica consiste in 100 quesiti strutturati in: ricostruire la figura, serie numeriche, quesiti sulla meccanica in generale e di chimica elementare, leve, ingranaggi, forze, pesi, circuiti elettrici, corpi immersi nei liquidi, idraulica ed equilibrio di oggetti, completamento sequenza di immagini, sinonimi e trovare la parola identica.\nLa prova di logica verrà superata con una valutazione di almeno 50/100 punti." },
    { input: "in cosa consistono le visite mediche?", output: "Le visite mediche consistono in prelievo del sangue; drug test; otorino (farà mettere delle cuffie e si dovrà alzare la mano al corrispondente suono, successivamente ci sarà la visita naso-bocca); composizione corporea (BIA) in cui un medico controllerà massa magra, grassa e altezza, successivamente un altro controllerà la schiena); dentista; oculista; psicologo ed eventuale psichiatra; cardiologo." },
    { input: "in quanti giorni si svolge il concorso in marina militare?", output: "Si svolge in tre giorni (il primo verranno svolti i quiz di logica e se verranno superati successivamente il difesa test, completamento frasi e questionario autobiografico, il secondo giorno verranno svolte visite mediche e psicologo, il terzo sarà un giorno tranquillo in cui spiegheranno tramite dei video i compiti della Marina Militare e vi consegneranno l’idoneità/inidoneità)." },
    { input: "come bisogna vestirsi per il concorso in marina militare?", output: "Bisogna vestirsi in modo consono senza strappi, scollature e pantaloncini, evitare orecchini collane e bracciali." },
    { input: "quali devono essere i valori della BIA nel concorso in marina militare?", output: "I valori della BIA devono essere per i concorrenti di sesso femminile massa grassa massimo 33% minimo 12%, per i concorrenti di sesso maschile massa grassa massimo 22% minimo 7%." },
    { input: "quando uscirà il calendario per le prove scritte di guardia di finanza?", output: "Pochi giorni dopo il 21 ottobre, le prove inizieranno dal 6 novembre." },
    { input: "quando uscirà la banca dati per guardia di finanza?", output: "La banca dati non sarà pubblicata." },
    { input: "dove si svolgeranno le preselettive per guardia di finanza?", output: "Le prove si svolgeranno alla Nuova Fiera di Roma." },
    { input: "come si svolgeranno le prove preselettive di guardia di finanza?", output: "La prova d’esame è articolata in 90 domande su quesiti di grammatica italiana, logica, cittadinanza e costituzione, storia e geografia da svolgere in 100 minuti." },
    { input: "quanto è il punteggio minimo per superare la prova per guardia di finanza?", output: "Dipende dallo \"sbarramento\" che ci sarà per il contingente scelto." },
    { input: "dove è possibile visionare il punteggio della preselettiva per guardia di finanza?", output: "Sarà possibile prendere visione del punteggio accedendo personalmente sul sito della GdF oppure sull’app dedicata." },
    { input: "come saranno strutturati i questionari per guardia di finanza?", output: "I questionari avranno quesiti divisi in base al grado di difficoltà e saranno distribuiti così:\n- 42 domande facili\n- 48 domande difficili." },
    { input: "quando uscirà il calendario per le prove di efficienza fisica per guardia di finanza?", output: "Circa 20 giorni dopo le preselettive." },
    { input: "quali saranno i punteggi per ogni domanda per la preselettiva per guardia di finanza?", output: "Per ogni risposta esatta si ottengono 1 o 3 punti, per risposta errata 0 punti, per le risposte omesse 0 punti." },
    { input: "in cosa consistono le prove fisiche per guardia di finanza?", output: "Le prove fisiche consistono in corsa di 1 km, salto in alto e piegamenti sulle braccia." },
    { input: "cosa bisogna portare il giorno delle prove fisiche per guardia di finanza?", output: "Il candidato dovrà portare con sé documento di riconoscimento valido e certificato di idoneità sportiva per atletica leggera." },
    { input: "cosa bisogna portare alle psicoattitudinali per guardia di finanza?", output: "Bisogna portare con sé tutti i documenti richiesti nel bando." },
    { input: "quali sono le prove fisiche per l'esercito?", output: "Flessioni, corsa, addominali alla sbarra." },
    { input: "quali visite si fanno al concorso per l'esercito?", output: "BIA, oculista, otorino, cardiologo, medico generale." },
    { input: "con la CSU quali visite faccio durante il concorso per l'esercito?", output: "Solo BIA e medico generale." },
    { input: "mi posso allenare dopo aver fatto il Mantoux?", output: "Certo, bisogna solo fare attenzione a non strofinare la zona che è stata fatta l’iniezione." },
    { input: "che cos’è il modulo K?", output: "Sono 3 mesi di specializzazione che si fanno dopo il RAV." },
    { input: "come si svolgono le prove a Bari?", output: "Primo giorno fisiche/test, secondo giorno mediche, terzo giorno colloquio con lo psicologo." },
    { input: "come si svolgono le prove a Palermo?", output: "Primo giorno fisiche, secondo giorno mediche/test, terzo giorno colloquio con lo psicologo." },
    { input: "come si svolgono le prove a Milano?", output: "Fisiche/test, colloquio con lo psicologo, mediche." },
    { input: "che punteggio bisogna fare per stare tranquilli nell'esercito?", output: "Dai 17 in su." },
    { input: "cosa bisogna portare il primo giorno di prova per il concorso nell'esercito?", output: "La documentazione richiesta dal bando, tutte le analisi e la carta d’identità." },
    { input: "quanti punti sono per la corsa per i maschi?", output: "I punti della corsa sono:\n- 10:20 = 1 punto\n- 9:40 = 2 punti\n- 9:00 = 3 punti\n- 8:20 = 4 punti\n- 7:40 = 5 punti" },
    { input: "quanti punti sono per la corsa per le ragazze?", output: "I punti della corsa sono:\n- 13 minuti = 0 punti, solo idoneità\n- 12:20 = 1 punto\n- 11:40 = 2 punti\n- 11:00 = 3 punti\n- 10:20 = 4 punti\n- 9:40 = 5 punti" },
    { input: "chi può partecipare al concorso?", output: "I candidati al concorso possono partecipare presentando domanda sia per la categoria civile sia per la categoria militare, che comprende sia coloro che sono in servizio sia coloro in congedo." },
    { input: "quando uscirà la banca dati per il concorso allievi carabinieri?", output: "Pochi giorni dopo l’uscita del bando." },
    { input: "dove sarà pubblicata la banca dati per allievi carabinieri?", output: "Verrà pubblicata sul sito istituzionale www.carabinieri.it e sarà disponibile anche nell’app ufficiale dell’Arma SimulatoriConcorsi." },
    { input: "dove si svolgeranno le preselettive per allievi carabinieri?", output: "Le prove si svolgeranno presso il Centro Nazionale di Selezione e Reclutamento nella caserma Salvo D’Acquisto in Via Tor di Quinto a Roma." },
    { input: "come si svolgerà la prova preselettiva per allievi carabinieri?", output: "La prova d’esame è articolata in 100 domande su quesiti di cultura generale da svolgere in 60 minuti." },
    { input: "quanto è il punteggio minimo per superare la prova per i carabinieri?", output: "La prova si considera superata se il candidato riporta una valutazione uguale o superiore a 51/100. Bisogna però precisare che per accedere allo step successivo bisogna superare la soglia di sbarramento, il punteggio del candidato che nella graduatoria provvisoria si classifica al posto n. X. Il numero X è comunicato sul bando e determina il numero di persone che passeranno allo step successivo." },
    { input: "dove è possibile visionare il punteggio della preselettiva per i carabinieri?", output: "Sarà possibile prendere visione del punteggio accedendo personalmente sul sito istituzionale. Successivamente si avrà l’opportunità di visionare il proprio questionario e modulo risposta test e, in caso di errori nella valutazione, presentare richiesta di revisione." },
    { input: "quali sono le materie oggetto d’esame per la preselettiva dei carabinieri?", output: "Le materie su cui verteranno le prove sono: italiano, storia, matematica, geometria, cittadinanza e costituzione, informatica, storia dell’arma, struttura ordinativa dell’arma, scienze, capacità verbale, ragionamento numerico, attualità, geografia. Tutte queste materie elencate saranno presenti in banca dati, a differenza delle due materie inedite: lingua straniera e ragionamento verbale." },
    { input: "come saranno strutturati i questionari per allievi carabinieri?", output: "I questionari saranno generati da un sistema di 'randomizzazione automatizzata', avranno una differente composizione pur rispondendo, nella loro diversità, a equivalente livello di difficoltà. La ripartizione delle domande è pertanto casuale." },
    { input: "come si calcola il punteggio della prova preselettiva per allievi carabinieri?", output: "Ad ogni risposta esatta verrà attribuito 1 punto, ad ogni risposta sbagliata oppure omessa 0 punti. Pertanto, non c'è decurtazione per le risposte errate." },
    { input: "in cosa consistono le prove fisiche per allievi carabinieri?", output: "Le prove fisiche consistono in corsa piana di 1 km, piegamenti sulle braccia e salto in alto." },
    { input: "quali sono i documenti da presentare il giorno delle prove fisiche per allievi carabinieri?", output: "Il candidato dovrà portare con sé il documento di riconoscimento valido e il certificato di idoneità sportiva per l'atletica leggera. Per le candidate sarà necessario anche un test di gravidanza non anteriore a 5 giorni, escluso il giorno della prova. Entrambi devono essere effettuati presso una struttura pubblica o privata accreditata dal SSN o SSR." },
    { input: "quanti punti sono per la corsa per gli uomini ai test fisici degli allievi carabinieri?", output: "I punti incrementali della corsa per gli uomini sono: tempo uguale o inferiore a 3' e 50'' = 1 punto, tempo compreso tra 3' e 51'' e 4' e 01'' = 0,5 punti, tempo compreso tra 4' e 02'' e 4' e 12'' = 0,25 punti." },
    { input: "quanti punti sono per la corsa per le donne ai test fisici degli allievi carabinieri?", output: "I punti incrementali della corsa per le donne sono: tempo uguale o inferiore a 4' e 40'' = 1 punto, tempo compreso tra 4' e 41'' e 5' = 0,5 punti, tempo compreso tra 5' e 01'' e 5' e 20'' = 0,25 punti." },
    { input: "chi può partecipare al concorso per polizia di stato?", output: "Possono partecipare cittadini italiani o di uno degli Stati membri dell'Unione Europea o di paesi terzi, regolarmente soggiornanti in Italia, in possesso dei requisiti previsti dal bando di concorso." },
    { input: "quando uscirà la banca dati per il concorso per polizia di stato?", output: "La banca dati sarà pubblicata poco dopo l'uscita del bando di concorso." },
    { input: "dove sarà pubblicata la banca dati per polizia di stato?", output: "La banca dati sarà pubblicata sul sito istituzionale della Polizia di Stato all'indirizzo www.poliziadistato.it." },
    { input: "dove si svolgeranno le preselettive per polizia di stato?", output: "Le prove preselettive si svolgeranno in una delle sedi indicate nel bando di concorso. Solitamente, le prove si svolgono presso la Nuova Fiera di Roma." },
    { input: "come si svolgeranno le preselettive per polizia di stato?", output: "La prova preselettiva consisterà in un test di 80 domande a risposta multipla su materie diverse come italiano, storia, matematica, cittadinanza e costituzione, lingua inglese, informatica, ragionamento logico e attitudinale." },
    { input: "qual è il punteggio minimo per superare la prova preselettiva per polizia di stato?", output: "Il punteggio minimo per superare la prova preselettiva è stabilito dal bando di concorso e varia di anno in anno. I candidati che ottengono un punteggio uguale o superiore a tale soglia passano alla fase successiva." },
    { input: "dove è possibile visionare il punteggio della preselettiva per polizia di stato?", output: "Sarà possibile prendere visione del punteggio accedendo personalmente al sito della Polizia di Stato, seguendo le istruzioni fornite nel bando di concorso." },
    { input: "quali sono le materie oggetto d’esame per la preselettiva di polizia di stato?", output: "Le materie oggetto d'esame per la preselettiva sono: italiano, storia, matematica/geometria, cittadinanza e costituzione, lingua inglese, informatica e quesiti di ragionamento logico-matematico e critico-verbale." },
    { input: "come saranno strutturati i questionari per polizia di stato?", output: "I questionari saranno divisi in base al grado di difficoltà. Saranno presenti domande facili, medie e difficili. La ripartizione delle domande può variare ogni anno, ma solitamente la maggior parte delle domande è di tipo medio." },
    { input: "come si calcola il punteggio della prova preselettiva per polizia di stato?", output: "Ad ogni risposta esatta verrà attribuito un punteggio positivo, mentre ad ogni risposta errata o non data verrà attribuito un punteggio negativo. Il punteggio totale sarà la somma dei punteggi ottenuti su tutte le domande." },
    { input: "in cosa consistono le prove fisiche per polizia di stato?", output: "Le prove fisiche prevedono: corsa di resistenza, sollevamento pesi, nuoto e addominali. I requisiti variano in base all'età e al sesso del candidato." },
    { input: "quali sono i documenti da presentare il giorno delle prove fisiche per polizia di stato?", output: "I documenti richiesti includono: documento di riconoscimento valido, certificato medico rilasciato da una struttura pubblica o privata accreditata, certificato di idoneità sportiva per attività agonistica rilasciato da una struttura pubblica o privata accreditata e certificato di partecipazione al corso di preparazione atletica rilasciato da una struttura pubblica o privata accreditata." },
    { input: "quanti punti sono necessari per superare la prova fisica per polizia di stato?", output: "I punteggi richiesti variano in base all'età e al sesso del candidato. È importante consultare il bando di concorso per conoscere i requisiti specifici." },
    { input: "quali sono i punteggi per la corsa per polizia di stato?", output: "I punteggi per la corsa variano in base all'età e al sesso del candidato. È necessario consultare il bando di concorso per conoscere i punteggi specifici." }
];

const findBestResponse = (input) => {
  const matches = responses.map((response) => {
    return {
      ...response,
      similarity: stringSimilarity.compareTwoStrings(input, response.input.toLowerCase())
    };
  });
  matches.sort((a, b) => b.similarity - a.similarity);
  return matches[0].similarity > 0.3 ? matches[0].output : "Mi dispiace, non ho capito. Puoi ripetere?";
};

const ChatBotResponder = () => {
  useEffect(() => {
    const subscription = supabase
      .channel('custom-all-channel')
      .on('postgres_changes', { event: 'INSERT', schema: 'public', table: 'posts' }, async (payload) => {
        const { content, id } = payload.new;
        const input = content.toLowerCase();
        const response = findBestResponse(input);

        if (response) {
          const { error } = await supabase
            .from('comments')
            .insert([{ content: response, post_id: id, user_id: 'ea886f69-4c2e-4858-b27c-4bb949f9f3ef' }]);

          if (error) {
            console.error('Error creating comment:', error);
          }
        }
      })
      .subscribe();

    return () => {
      supabase.removeChannel(subscription);
    };
  }, []);

  return null;
};

export default ChatBotResponder;
