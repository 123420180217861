import React, { useEffect, useState } from 'react';
import { useAuth } from '../context/AuthContext';
import supabase from '../supabase';

const Support = () => {
  const { currentUser } = useAuth();
  const [userPoints, setUserPoints] = useState(0);

  useEffect(() => {
    if (currentUser) {
      const fetchUserPoints = async () => {
        const { data, error } = await supabase
          .from('user_points')
          .select('points')
          .eq('user_id', currentUser.id)
          .single();

        if (error) {
          console.error('Error fetching user points:', error);
        } else {
          setUserPoints(data.points);
        }
      };
      fetchUserPoints();
    }
  }, [currentUser]);

  const isVIP = userPoints >= 1000;

  return (
    <div className="p-6 bg-gray-900 text-white rounded-lg shadow-lg">
      <h1 className="text-2xl font-bold mb-4">Supporto</h1>
      {isVIP ? (
        <p className="text-lg">Benvenuto nel supporto prioritario VIP. Il nostro team ti assisterà il prima possibile.</p>
      ) : (
        <p className="text-lg">Per ricevere supporto prioritario, raggiungi 1000 punti VIP!</p>
      )}
    </div>
  );
};

export default Support;
