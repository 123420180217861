import React from 'react';
import { FaThumbsUp, FaHeart, FaSurprise } from 'react-icons/fa';
import supabase from '../supabase';

const Reaction = ({ reactions = [], postId, currentUser, fetchPosts }) => {
  const handleReaction = async (postId, type) => {
    if (!currentUser) {
      console.error('Devi essere autenticato per reagire ai post.');
      return;
    }

    try {
      const { error } = await supabase
        .from('reactions')
        .insert([{ post_id: postId, user_id: currentUser.id, type }]);

      if (error) {
        throw error;
      }

      if (fetchPosts) {
        fetchPosts(); // Aggiorna i post dopo aver aggiunto una reazione
      }
    } catch (error) {
      console.error('Error handling reaction:', error);
    }
  };

  return (
    <div className="flex space-x-4">
      <button
        onClick={() => handleReaction(postId, 'like')}
        className="flex items-center space-x-1 text-yellow-300 hover:text-yellow-500 transition duration-300"
      >
        <FaThumbsUp /> <span>{reactions.filter(r => r.type === 'like').length}</span>
      </button>
      <button
        onClick={() => handleReaction(postId, 'love')}
        className="flex items-center space-x-1 text-red-300 hover:text-red-500 transition duration-300"
      >
        <FaHeart /> <span>{reactions.filter(r => r.type === 'love').length}</span>
      </button>
      <button
        onClick={() => handleReaction(postId, 'wow')}
        className="flex items-center space-x-1 text-teal-300 hover:text-teal-500 transition duration-300"
      >
        <FaSurprise /> <span>{reactions.filter(r => r.type === 'wow').length}</span>
      </button>
    </div>
  );
};

export default Reaction;

