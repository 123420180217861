import React from 'react';
import { motion } from 'framer-motion';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import Sidebar from '../components/Sidebar'; // Import Sidebar
import { useAuth } from '../context/AuthContext'; // Import useAuth

function ManualsPage() {
  const { currentUser } = useAuth(); // Use useAuth to get the currentUser
  const logoImageUrl = 'https://i.imgur.com/LgLmg3k.jpg';

  return (
    <div className="min-h-screen flex flex-col lg:flex-row bg-gray-900 text-white">
      {currentUser && <Sidebar currentUser={currentUser} />}
      <div className="flex-1 lg:ml-72">
        <Navbar />
        <main className="flex-1 container mx-auto mt-5 p-4">
          <div className="bg-gray-800 p-8 rounded-lg shadow-lg">
            <div className="flex items-center justify-center mb-8">
              <img src={logoImageUrl} alt="Logo" className="h-12 mr-2" />
              <h1 className="text-4xl font-bold text-center text-red-500">
                Manuali
              </h1>
            </div>
            <p className="text-lg text-white mb-8">
              Benvenuto nella nostra sezione dei manuali, dove puoi trovare risorse essenziali per prepararti agli esami nelle forze armate e nelle agenzie di polizia.
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
                className="bg-gray-700 p-6 rounded-lg shadow-md"
              >
                <h2 className="text-xl font-semibold text-yellow-400 mb-4">Manuale dell'Arma dei Carabinieri</h2>
                <p className="text-base text-gray-300 mb-4">
                  Scopri il manuale completo per il concorso dei Carabinieri con un approccio innovativo e facile da comprendere.
                </p>
                <ul className="text-sm text-gray-400">
                  <li>- Storia dei Carabinieri</li>
                  <li>- Date Importanti</li>
                  <li>- Elementi Simbolici</li>
                  {/* Aggiungi più dettagli se necessario */}
                </ul>
                <div className="text-center mt-4">
                  <a href="https://linktr.ee/concorsinfo.com" className="inline-block bg-red-500 text-white px-4 py-2 rounded-md transition duration-300 hover:bg-red-600">Acquista</a>
                </div>
              </motion.div>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.2 }}
                className="bg-gray-700 p-6 rounded-lg shadow-md"
              >
                <h2 className="text-xl font-semibold text-yellow-400 mb-4">Manuale dell'Esercito Italiano</h2>
                <p className="text-base text-gray-300 mb-4">
                  Esplora il manuale completo per il concorso dell'Esercito Italiano con un approccio innovativo e facile da comprendere.
                </p>
                <ul className="text-sm text-gray-400">
                  <li>- Guida alle Fasi dell'Esame</li>
                  <li>- Test MMPI-2</li>
                  <li>- Consigli per i Colloqui</li>
                  {/* Aggiungi più dettagli se necessario */}
                </ul>
                <div className="text-center mt-4">
                  <a href="https://linktr.ee/concorsinfo.com" className="inline-block bg-red-500 text-white px-4 py-2 rounded-md transition duration-300 hover:bg-red-600">Acquista</a>
                </div>
              </motion.div>
              {/* Card Manuale 3: 1673 Allievi Finanzieri */}
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.4 }}
                className="bg-gray-700 p-6 rounded-lg shadow-md"
              >
                <h2 className="text-xl font-semibold text-yellow-400 mb-4">Manuale 1673 Allievi Finanzieri</h2>
                <p className="text-base text-gray-300 mb-4">
                  Scopri il manuale completo per il concorso degli Allievi Finanzieri e preparati in modo efficace per la tua carriera nella finanza.
                </p>
                <ul className="text-sm text-gray-400">
                  <li>- Guide di Studio Approfondite</li>
                  <li>- Test Pratici e Simulazioni di Esame</li>
                  <li>- Consigli per Superare gli Accertamenti Psico-Fisici</li>
                </ul>
                <div className="text-center mt-4">
                  <a href="https://linktr.ee/concorsinfo.com" className="inline-block bg-red-500 text-white px-4 py-2 rounded-md transition duration-300 hover:bg-red-600">Acquista</a>
                </div>
              </motion.div>
              {/* Card Manuale 4: Manuale VFI AM */}
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5, delay: 0.6 }}
                className="bg-gray-700 p-6 rounded-lg shadow-md"
              >
                <h2 className="text-xl font-semibold text-yellow-400 mb-4">Manuale VFI AM</h2>
                <p className="text-base text-gray-300 mb-4">
                  Il manuale completo per il concorso VFI AM ti prepara in modo approfondito per la selezione nell'Aviazione Militare.
                </p>
                <ul className="text-sm text-gray-400">
                  <li>- Preparazione Avanzata per le Prove di Efficienza Fisica</li>
                  <li>- Consigli Strategici per le Interviste e i Colloqui</li>
                  <li>- Analisi Dettagliate dei Test Psico-Attitudinali</li>
                </ul>
                <div className="text-center mt-4">
                  <a href="https://linktr.ee/concorsinfo.com" className="inline-block bg-red-500 text-white px-4 py-2 rounded-md transition duration-300 hover:bg-red-600">Acquista</a>
                </div>
              </motion.div>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
}

export default ManualsPage;
