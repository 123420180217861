import React, { useState } from 'react';
import { useAuth } from '../context/AuthContext';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const { login } = useAuth();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    const { error } = await login(email, password);

    if (error) {
      setError(error.message);
    } else {
      navigate('/dashboard');
    }

    setLoading(false);
  };

  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-900 text-white">
      <form onSubmit={handleLogin} className="bg-gray-800 p-6 rounded-lg shadow-lg space-y-4 w-full max-w-md">
        <h2 className="text-2xl font-bold">Login</h2>
        {error && <p className="text-red-500">{error}</p>}
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className="w-full p-2 rounded-md bg-gray-700 text-white"
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="w-full p-2 rounded-md bg-gray-700 text-white"
        />
        <button
          type="submit"
          disabled={loading}
          className={`w-full p-2 rounded-md text-white font-bold ${loading ? 'bg-teal-300' : 'bg-teal-500'}`}
        >
          {loading ? 'Loading...' : 'Login'}
        </button>
        <button
          type="button"
          onClick={() => navigate('/register')}
          className="w-full p-2 mt-4 rounded-md bg-teal-500 text-white font-bold"
        >
          Non hai un account? Registrati ora
        </button>
      </form>
    </div>
  );
};

export default Login;
